import React from "react";
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip,} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {Skeleton} from "antd";
import {useChartData} from "../hooks/useChartData";

ChartJS.register(
    CategoryScale,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
);

const styles = {
    title: {},
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
        fontSize: "30px",
        fontWeight: "600",
        color: "#424242",
    },
    card: {
        height: "300px",
        zIndex: 20,
        backgroundColor: "#ffffff",
        // fontSize: "16px",
        // fontWeight: "500",
    },
};

function Chart(tokenData) {
    const {chartData} = useChartData(tokenData);

    const options = {
        responsive: true,
        aspectRatio: 1.5,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Payflow chart",
            }
        }
    };

    return (
        <div style={styles.card}>
            <Skeleton loading={!chartData} title="Fetching data...">
                <Line options={options} data={chartData}/>
            </Skeleton>
        </div>
    );
}

export default Chart;