import { Card, Timeline, Typography } from "antd";
import React from "react";
import Chart from "./Chart";

const { Text } = Typography;

const styles = {
  title: {
    fontSize: "20px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "0.5rem",
  },
  timeline: {
    marginBottom: "-45px",
  },
};

export default function QuickStart({ isServerInfo }) {

  const pftToken = {
    chain : "bsc", // 0x38
    address : "0xe3b42852a85d38b18076ab2dd96b0f894cc0636c",
    decimals : 6,
    logoURI: "https://bscscan.com/token/images/payflowtoken2_32.png",
    name : "PayFlow",
    symbol : "PFT"
  };
  const pachaToken = {
    chain : "bsc", // 0x38
    address : "0xF670F753fFb498ec004E02b1fD488f94795e82d6",
    decimals : 9,
    logoURI: "https://bscscan.com/token/images/paychain_32.png",
    name : "PayChain",
    symbol : "PACHA"
  };

  return (
    <div style={{ gap: "10px" }}>
      <div style={{ width: "100%", marginBottom: "10px" }}>
        <Card
            style={styles.card}
            title={
              <>
                📈 <Text strong>Chart PayFlow</Text>
              </>
            }
        >
          <Chart token={pftToken} />
        </Card>
      </div>
      <div style={{ width: "100%" }}>
        <Card
          style={styles.card}
          title={
            <>
              🚀 <Text strong>Quick Start</Text>
            </>
          }
        >
          <Timeline mode="left" style={styles.timeline}>
            <Timeline.Item dot="👛">
              <Text style={styles.text}>
                Transfer - Send an amount of assets to a wallet address
              </Text>
            </Timeline.Item>

            <Timeline.Item dot="🔃">
              <Text style={styles.text}>
                Swap - Exchange one asset for another
              </Text>
            </Timeline.Item>

            <Timeline.Item dot="💱">
              <Text style={styles.text}>
                Buy - Acquire assets with fiat money
              </Text>
            </Timeline.Item>

            <Timeline.Item dot="💰">
              <Text style={styles.text}>
                Balances - View the assets in your wallet
              </Text>
            </Timeline.Item>

            <Timeline.Item dot="💸">
              <Text style={styles.text}>
                Transactions - View the transactions made with your wallet
              </Text>
            </Timeline.Item>

            <Timeline.Item dot="🖼">
              <Text style={styles.text}>
                NFTs - View the Non-Fungible Tokens in your wallet
              </Text>
            </Timeline.Item>

          </Timeline>
        </Card>
      </div>
    </div>
  );
}
