import { useState, useEffect } from "react";
import { useMoralis, useERC20Balances, useChain} from "react-moralis";
import { Skeleton, Table} from "antd";
import { getEllipsisTxt } from "../helpers/formatters";
import { AvaxLogo, PolygonLogo, BSCLogo, ETHLogo } from "./Chains/Logos";
import { CopyUrlButton } from "../hooks/useCopyToClipboard";

function ERC20Balance(props) {
  const { data: assets } = useERC20Balances(props);
  const { Moralis } = useMoralis();
  const { chainId } = useChain();
  const [tokens, setTokens] = useState([]);

  const useExchangeName = (chain) => {
    if (chain === "eth" || chain === "0x1" || chain === "ropsten" || chain === "0x3" || chain === "rinkeby" || chain === "0x4" || chain === "goerli" || chain === "0x5" || chain === "kovan" || chain === "0x2a")
      return "uniswapv3";
    else if (chain === "bsc" || chain === "0x38" || chain === "bsc testnet" || chain === "0x61")
      return "PancakeSwapV2";
    else if (chain === "polygon" || chain === "0x89" || chain === "mumbai" || chain === "0x13881")
      return "quickswap";
    else if (chain === "avalanche" || chain === "0xa86a" || chain === "avalanche testnet" || chain === "0xa869")
      return "";
    else
      return "uniswapv3";
  }

  const chainName = useExchangeName(chainId);

  useEffect( ()  =>  {
    const defaultLogoBasedOnChain = (chain) => {
      if (chain === "eth" || chain === "0x1" || chain === "ropsten" || chain === "0x3" || chain === "rinkeby" || chain === "0x4" || chain === "goerli" || chain === "0x5" || chain === "kovan" || chain === "0x2a")
        return <ETHLogo/>;
      else if (chain === "bsc" || chain === "0x38" || chain === "bsc testnet" || chain === "0x61")
        return <BSCLogo/>;
      else if (chain === "polygon" || chain === "0x89" || chain === "mumbai" || chain === "0x13881")
        return <PolygonLogo/>;
      else if (chain === "avalanche" || chain === "0xa86a" || chain === "avalanche testnet" || chain === "0xa869")
        return <AvaxLogo/>;
      else
        return <ETHLogo/>;
    };

    if (assets) {
      setTokens([]);
      assets.map( async (token)  =>  {
        let price = await Moralis.Web3API.token.getTokenPrice({address: token.token_address, chain: chainId, exchange: chainName});
        let balance = parseFloat(Moralis.Units.FromWei(token.balance, token.decimals).toFixed(6));
        let orgValue = price.usdPrice * balance;
        let usd_value = (Math.round(orgValue * 100) / 100).toFixed(2);
        console.log(token.name + " -> " + balance + " : " + usd_value);

        let newObj = {
          "key": token.token_address,
          "logo": token.logo ? <img
              src={token.logo}
              alt="nologo"
              width="28px"
              height="28px"
          /> : defaultLogoBasedOnChain(chainId),
          "name": token.name,
          "symbol": token.symbol,
          "balance": balance,
          "token_address": token.token_address,
          "usd_value": usd_value
        }
        setTokens(tokens => [...tokens, newObj]);
      })

    }
  }, [assets, Moralis, chainId, chainName]);

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => logo,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
      sorter: {
        compare: (a, b) => {
          let nameA = a.name.toUpperCase(); // ignore upper and lowercase
          let nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; //nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0;  // names must be equal
        },
      },
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (symbol) => symbol,
      sorter: {
        compare: (a, b) => {
          let nameA = a.symbol.toUpperCase(); // ignore upper and lowercase
          let nameB = b.symbol.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; //nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0;  // names must be equal
        },
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => balance,
      sorter: {
        compare: (a, b) => a.balance - b.balance,
      },
    },
    {
      title: "USD Value",
      dataIndex: "usd_value",
      key: "usd_value",
      render: (usd_value) => usd_value,
      sorter: {
        compare: (a, b) => a.usd_value - b.usd_value,
      },
    },
    {
      title: "Address",
      dataIndex: "token_address",
      key: "token_address",
      render: (token_address) => (
        <>
          {getEllipsisTxt(token_address, 5)} &nbsp;
          <CopyUrlButton url={token_address} />
        </>
      ),
    },
  ];

  return (
    <div style={{ width: "65vw", padding: "15px" }}>
      <h1>💰 Token Balances</h1>
      <Skeleton loading={!tokens} title="Connect wallet...">
        <Table
          size="small"
          dataSource={tokens}
          columns={columns}
          rowKey={(record) => {
            return record.token_address;
          }}
        />
      </Skeleton>
    </div>
  );
}

export default ERC20Balance;
