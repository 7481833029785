import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export const useChartData = (params) => {

  let [chartData, setChartData] = useState();

  useEffect(() => {
      fetchChartData(params).then((result) => setChartData(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dates = Array(5).fill().map((value,i) =>
      moment().subtract(i, "d").format("YYYY-MM-DD")
  ).reverse();

  const Web3Api = axios.create({
    baseURL: 'https://deep-index.moralis.io/api/v2',
    headers: {"X-API-Key": "e37UM4jrMLvFblvHyfzUe2ZVvojOYifcfCuteTFxHwodTypySiVnUQrVdORr64Bb", "accept": "application/json"}
  });
  axios.defaults.headers.common = {
    "accept": "application/json",
    "X-API-Key": "e37UM4jrMLvFblvHyfzUe2ZVvojOYifcfCuteTFxHwodTypySiVnUQrVdORr64Bb",
  };

  const fetchBlockData = async (tokenData, dates) => {
    const blockData = await Promise.allSettled(dates.map(async (dateString, i) =>
        Web3Api.get("/dateToBlock?chain=" + tokenData.chain + "&date=" + dateString, {})
    ));
    return blockData;
  }

  const fetchPriceData = async (tokenData, dates, blocks) => {
    const priceData = await Promise.allSettled(blocks.map(async (blockObject,i) =>
        Web3Api.get("/erc20/" + tokenData.address + "/price?chain=" + tokenData.chain + "&to_block=" + blockObject.value.data.block, {})
    ));
    const prices = priceData.map(e => e.value.data.usdPrice);
    let avgPrices = [];
    {prices.map(
        function(price, index) {
          if (avgPrices.length === 0)
          {
            avgPrices.push(price);
          }
          else
          {
            let currentAvg = avgPrices.reduce((a,b) => a + parseFloat(b),0) / avgPrices.length;
            let newAvg = (currentAvg + price) / 2;
            avgPrices.push(newAvg);
          }
        }
    )}

    chartData = {
      labels: dates,
      datasets: [
        {
          label: tokenData.symbol,
          data: prices,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          fill: false,
          lineTension: 0.1,
          borderWidth: 1,
          scales: {
            yAxis: [{
              ticks: {
                callback: function(tick, index, ticks) {
                  return String(ticks[index]);
                }
              }
            }]
          }
        },
        {
          label: "Average",
          data: avgPrices,
          borderColor: 'rgb(3, 104, 204)',
          backgroundColor: 'rgb(4, 123, 241, 0.5)',
          fill: false,
          lineTension: 0.5,
          borderWidth: 1,
          scales: {
            yAxis: [{
              ticks: {
                callback: function(tick, index, ticks) {
                  return String(ticks[index]);
                }
              }
            }]
          }
        },
      ],
    };
    return chartData;
  }

  const fetchChartData = async (params) => {
    const pftToken = {
      chain : "bsc", // 0x38
      address : "0xe3b42852a85d38b18076ab2dd96b0f894cc0636c",
      decimals : 6,
      logoURI: "https://bscscan.com/token/images/payflowtoken2_32.png",
      name : "PayFlow",
      symbol : "PFT"
    };
    if (!params.tokenData) {
      params.tokenData = pftToken;
    }

    return await fetchBlockData(params.tokenData, dates)
        .then((blocks) => fetchPriceData(params.tokenData, dates, blocks)
              .then((prices) => prices)
        );
  }

  return { fetchChartData, chartData };
};
