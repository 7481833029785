import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Account from "components/Account/Account";
import Chains from "components/Chains";
import Chart from "components/Chart";
import TokenPrice from "components/TokenPrice";
import NativeERC20Balance from "components/ERC20Balance";
import ERC20Transfers from "components/ERC20Transfers";
import DEX from "components/DEX";
import NFTBalance from "components/NFTBalance";
import Wallet from "components/Wallet";
import { Layout, Tabs } from "antd";
import "antd/dist/antd.css";
import "./style.css";
import QuickStart from "components/QuickStart";
import Text from "antd/lib/typography/Text";
import Ramper from "components/Ramper";
import MenuItems from "./components/MenuItems";
import Particles from "react-tsparticles";
const { Header, Footer } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#fff",
    marginTop: "60px",
    padding: "10px",
    zIndex: 2,
  },
  header: {
    position: "fixed",
    zIndex: 5,
    width: "100%",
    background: "#001529",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#fff",
    paddingLeft: "12px",
  },
  footer: {
    textAlign: "center",
    zIndex: 2,
  }
};

const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } = useMoralis();

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  const Ptokens = [{
    chain: "bsc",
    address : "0xe3b42852a85d38b18076ab2dd96b0f894cc0636c",
    decimals : 6,
    logoURI: "https://bscscan.com/token/images/payflowtoken2_32.png",
    name : "PayFlow",
    symbol : "PFT"
  },{
    chain: "bsc",
    address : "0xF670F753fFb498ec004E02b1fD488f94795e82d6",
    decimals : 9,
    logoURI: "https://bscscan.com/token/images/paychain_32.png",
    name : "PayChain",
    symbol : "PACHA"
  },{
    chain: "bsc",
    address : "0xFBAAE911a5B574F7671270e32AEDF18FD0532a9e",
    decimals : 9,
    logoURI: "https://bscscan.com/images/main/empty-token.png",
    name : "Unique Fortunes",
    symbol : "UTFT"
  }];

  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Router>
        <Header style={styles.header}>
          <Route path="/">
            <Logo />
          </Route>
          <MenuItems />
          <Account />
          <div style={styles.headerRight}>
            <TokenPrice
                address="0xe3b42852a85d38b18076ab2dd96b0f894cc0636c"
                chain="bsc"
                image="https://bscscan.com/token/images/payflowtoken2_32.png"
                size="32px"
            />
            <TokenPrice
                address="0xF670F753fFb498ec004E02b1fD488f94795e82d6"
                chain="bsc"
                image="https://bscscan.com/token/images/paychain_32.png"
                size="32px"
            />
            <Chains />
          </div>
        </Header>

        <div style={styles.content}>
          <Switch>
            <Route exact path="/quickstart">
              <QuickStart isServerInfo={isServerInfo} />
            </Route>
            <Route path="/wallet">
              <Wallet />
            </Route>
            <Route path="/swap">
              <Tabs defaultActiveKey="1" style={{ alignItems: "center" }}>
                <Tabs.TabPane tab={<span>Binance Smart Chain</span>} key="1">
                  <DEX chain="bsc" customTokens={Ptokens} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Ethereum</span>} key="2">
                  <DEX chain="eth" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Polygon</span>} key="3">
                  <DEX chain="polygon" />
                </Tabs.TabPane>
              </Tabs>
            </Route>
            <Route path="/balances">
              <NativeERC20Balance />
            </Route>
            <Route path="/buy">
              <Ramper />
            </Route>
            <Route path="/transactions">
              <ERC20Transfers />
            </Route>
            <Route path="/nfts">
              <NFTBalance />
            </Route>
            <Route path="/chart">
              <Chart tokenData={Ptokens[0]}/>
            </Route>
            <Route path="/nonauthenticated">
              <>Please login using the "Connect wallet" button</>
            </Route>
            <Route path="/">
              <Redirect to="/quickstart" />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer style={styles.footer}>
        <Text style={{ display: "block" }}>
          ⭐️ Read the{" "}
          <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.payflowtoken.com"
          >
            docs
          </a>
        </Text>
        <Text style={{ display: "block" }}>
          ⭐️ Built with{" "}
          <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://moralis.io"
          >
            Moralis
          </a>
          , coded by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://clsystems.nl"
          >
            CLSystems
        </a>
        </Text>
      </Footer>
      <Particles
          id="tsparticles"
          options={{
            "background": {
              "color": {
                "value": "#efefef"
              },
              "position": "50% 50%",
              "repeat": "no-repeat",
              "size": "cover",
              "opacity": 0.3
            },
            "fullScreen": {
              "zIndex": 1
            },
            "interactivity": {
              "events": {
                "onClick": {
                  "mode": "push"
                },
                "onHover": {
                  "enable": true,
                  "mode": "bubble"
                }
              },
              "modes": {
                "bubble": {
                  "distance": 400,
                  "duration": 2,
                  "opacity": 0.8,
                  "color": {
                    "value": "#ff0000"
                  },
                  "size": 40
                },
                "grab": {
                  "distance": 400
                }
              }
            },
            "particles": {
              "color": {
                "value": "#1b1e34"
              },
              "links": {
                "color": {
                  "value": "#ffffff"
                },
                "distance": 200,
                "width": 2
              },
              "move": {
                "attract": {
                  "rotate": {
                    "x": 600,
                    "y": 1200
                  }
                },
                "enable": true,
                "outModes": {
                  "bottom": "out",
                  "left": "out",
                  "right": "out",
                  "top": "out"
                },
                "speed": 3
              },
              "number": {
                "density": {
                  "enable": true
                },
                "value": 4
              },
              "opacity": {
                "random": {
                  "enable": true,
                  "minimumValue": 0.3
                },
                "value": {
                  "min": 0.3,
                  "max": 0.5
                },
                "animation": {
                  "speed": 1,
                  "minimumValue": 0.1
                }
              },
              "shape": {
                "options": {
                  "polygon": {
                    "sides": 6
                  },
                  "star": {
                    "sides": 6
                  }
                },
                "type": "polygon"
              },
              "size": {
                "random": {
                  "enable": true,
                  "minimumValue": 100
                },
                "value": {
                  "min": 50,
                  "max": 80
                },
                "animation": {
                  "minimumValue": 40
                }
              }
            }
          }}
      />
    </Layout>
  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>
    <img src="https://dapp.payflowtoken.com/payflow-logo-white-180x40.webp" height="40px" alt="PayFlow Token logo" />
  </div>
);

export default App;
